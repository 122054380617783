<template>
    <div style="background-color: #f4f4f4;height: 100%">
      <el-page-header style="margin-left: 37px;padding-top: 21px" @back="goBack"></el-page-header>
      <el-card class="el-card box-card is-always-shadow">

        <el-button style="margin-bottom: 20px;" @click="handleAdd">添加</el-button>

        <el-table :data="tableData" style="width: 800px" stripe border>

          <el-table-column prop="id" label="ID" width="50"> </el-table-column>

          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="create_timex" label="创建日期" > </el-table-column>

          <el-table-column
            prop="address"
            label="操作">
            <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>

        </el-table>
      </el-card>
    </div>
</template>

<script>
  import common from "@/utils/common";

    export default {
      components: {

      },
        name: "PanoramaMaterialKind",
        data(){
           return {
                    tableData: []
                  }
      },
      created() {

        this.init();

      },
      methods: {
        init(){
          common.connect("/distributorpcv1/Scene/list_sucai_kind",{name},(res)=>{
            this.tableData = res.data;
          });
        },
        goBack(){
          this.$router.back()
        },
        handleDelete(a,b){

          this.$confirm("是否删除").then(()=>{
            common.connect("/distributorpcv1/Scene/delete_sucai_kind",{id:b.id},(res)=>{
              this.$message("删除成功");
              this.tableData.splice(a, 1);//删除数组
            });
          });


        },
        handleAdd(){
           this.$prompt('请输入系列名称', '提示').then((res) => {
                var name = res.value;
                common.connect("/distributorpcv1/Scene/add_sucai_kind",{name},()=>{
                  this.$message("添加成功");
                  this.init();
                });
           })
        }

      }
    }
</script>

<style scoped>
.box-card {
    margin: 16px 39px;
    width: 90%;
    line-height: 20px;
    border-radius: 4px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.07);
    font-family: Roboto;
    border: 1px solid rgba(255, 255, 255, 100);
  }
</style>
